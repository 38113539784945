<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Name" :maxlength="128" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-text label="Code" :maxlength="32" placeholder="Enter code..." v-model="model.code" :validation="$v.model.code" notes="The code to be entered by attendees during checkout to receive the discount." />
			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" notes="Describe the terms of the discount in user friendly language as it will be shown if the discount is rejected." />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Status" v-model="model.status" :validation="$v.model.type" :options="$constants.registration.discount.statusName" :descriptions="$constants.registration.discount.statusDescription" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.registration.discount.typeName" :descriptions="$constants.registration.discount.typeDescription" />
			<app-input-text v-if="model.type !== $constants.registration.discount.type.comp" label="Discount" :numeric="true" :prefix="model.type === $constants.registration.discount.type.amount ? '$' : '%'" placeholder="Enter value..." v-model="model.amount.value" :validation="$v.model.amount.value" />
			<app-input-text v-if="model.type === $constants.registration.discount.type.percent" label="Max. Value" notes="The maximum discount that can be given regardless of the actual percentage value." :numeric="true" :toggle="model.amount.max.enabled" v-on:toggle="model.amount.max.enabled = !model.amount.max.enabled" v-model="model.amount.max.value" :validation="$v.model.amount.max.value" />

		</app-content-box>

		<app-content-box :tabs="contentTabs" :readonly="$authorised('con/registration/access', 'read')">

			<template v-slot:usage>

				<app-input-toggle label="Contexts" :multiple="true" :column="1" notes="The purchasing contexts where the code can be used in." :options="$constants.registration.discount.contextName" :toggle="model.context.enabled" :asString="true" v-on:toggle="model.context.enabled = !model.context.enabled" v-model="model.context.types" :validation="$v.model.context.types" />
				<app-input-toggle label="Badge Types" :multiple="true" :column="1" notes="The badge types which can use the code. When registering multiple badges, the primary badge must be a selected type." value-field="id" :options="references.badges" :toggle="model.badges.enabled" :asString="true" v-on:toggle="model.badges.enabled = !model.badges.enabled" v-model="model.badges.types" :validation="$v.model.badges.types" />
				<app-input-date :time="true" label="Usable From" placeholder="Enter date..." :toggle="model.from.enabled" v-on:toggle="model.from.enabled = !model.from.enabled" v-model="model.from.date" :validation="$v.model.from.date" />
				<app-input-date :time="true" label="Usable Until" placeholder="Enter date..." :toggle="model.to.enabled" v-on:toggle="model.to.enabled = !model.to.enabled" v-model="model.to.date" :validation="$v.model.to.date" />
				<app-input-text label="Code Uses" notes="Maximum number of times this code can be used across all attendees." :numeric="true" :toggle="model.uses.all.enabled" v-on:toggle="model.uses.all.enabled = !model.uses.all.enabled" v-model="model.uses.all.amount" :validation="$v.model.uses.all.amount" />
				<app-input-text label="Attendee Uses" notes="Maximum number of times this code can be used by an individual attendee." :numeric="true" :toggle="model.uses.badge.enabled" v-on:toggle="model.uses.badge.enabled = !model.uses.badge.enabled" v-model="model.uses.badge.amount" :validation="$v.model.uses.badge.amount" />
				<app-input-text label="Min. Spend" notes="The minimum required value of the basket total for the code to be usable." :numeric="true" :toggle="model.spend.min.enabled" v-on:toggle="model.spend.min.enabled = !model.spend.min.enabled" v-model="model.spend.min.value" :validation="$v.model.spend.min.value" />
				
			</template>

			<template v-slot:items>

				<app-input-text label="Min. Items" notes="The minimum number of items that must be in the basket." :numeric="true" :toggle="model.items.min.enabled" v-on:toggle="model.items.min.enabled = !model.items.min.enabled" v-model="model.items.min.amount" :validation="$v.model.spend.min.amount" />
				<app-input-text label="Max. Items" notes="The maximum number of items that can be in the basket." :numeric="true" :toggle="model.items.max.enabled" v-on:toggle="model.items.max.enabled = !model.items.max.enabled" v-model="model.items.max.amount" :validation="$v.model.spend.min.amount" />
				<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Eligibility" v-model="model.eligibility.enabled" :validation="$v.model.eligibility.enabled" :options="$constants.registration.discount.eligibleName" :descriptions="$constants.registration.discount.eligibleDescription" />
				<app-input-toggle v-if="model.eligibility.enabled === $constants.registration.discount.eligible.type" notes="Eligible items must be one of the selected types ." label="Types" :multiple="true" :column="1" :options="$constants.registration.item.typeName" v-model="model.eligibility.types" :validation="$v.model.eligibility.types" />
				<app-input-toggle v-if="model.eligibility.enabled === $constants.registration.discount.eligible.tag" notes="Eligible items must be one of the selected tags." label="Tags" :multiple="true" :column="1" :options="references.tags" v-model="model.eligibility.tags" :validation="$v.model.eligibility.tags" />
				<app-input-suggest v-if="model.eligibility.enabled === $constants.registration.discount.eligible.item" notes="Eligible items must be one of the selected items." label="Items" :exclude="model.eligibility.items" :multiple="true" placeholder="Search items..." v-model="model.eligibility.items" :validation="$v.model.eligibility.items" api="convention/registration/item/variant" />

			</template>

			<template v-slot:other>

				<app-input-suggest label="Required" notes="The attendee must already have these items to be eligible. Only applies to Store or Ticket Office purchases." :toggle="model.required.enabled" v-on:toggle="model.required.enabled = !model.required.enabled" :exclude="model.required.items" :multiple="true" placeholder="Search items..." v-model="model.required.items" :validation="$v.model.required.items" api="convention/registration/item/variant" />
				<app-input-suggest label="Comps" notes="The attendee will receive these items in addition to what they are purchasing." :toggle="model.comps.enabled" v-on:toggle="model.comps.enabled = !model.comps.enabled" :exclude="model.comps.items" :multiple="true" placeholder="Search items..." v-model="model.comps.items" :validation="$v.model.comps.items" api="convention/registration/item/variant" />

			</template>

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, between, maxValue, minValue } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				badges: [],
				tags: []
			},
			model: {
				id: false,
				name: '',
				code: '',
				description: '',
				status: 0,
				type: 0,
				amount: {
					value: 0,
					max: {
						enabled: 0,
						value: 0
					}
				},
				items: {
					min: {
						enabled: 0,
						amount: 0
					},
					max: {
						enabled: 0,
						amount: 0
					}
				},
				spend: {
					min: {
						enabled: 0,
						value: 0
					}
				},
				from: {
					enabled: 0,
					date: 0
				},
				to: {
					enabled: 0,
					date: 0
				},
				context: {
					enabled: 0,
					types: []
				},
				badges: {
					enabled: 0,
					types: []
				},
				eligibility: {
					enabled: 0,
					types: [],
					items: [],
					tags: []
				},
				uses: {
					all: {
						enabled: 0,
						amount: 0
					},
					badge: {
						enabled: 0,
						amount: 0
					}
				},
				required: {
					enabled: 0,
					items: []
				},
				comps: {
					enabled: 0,
					items: []
				}
			}
		}

	},

	validations: function() {

		return {
			model: {
				name: {
					required
				},
				code: {
					required
				},
				description: {
					required
				},
				type: {
					required,
					between: between(1, 3)
				},
				status: {
					required
				},
				amount: {
					value: {
						minValue: minValue(this.amountValueMin),
						maxValue: maxValue(this.amountValueMax)
					},
					max: {}
				},
				items: {
					min: {},
					max: {}
				},
				spend: {
					min: {}
				},
				from: {},
				to: {},
				context: {},
				badges: {},
				eligibility: {},
				uses: {
					all: {},
					badge: {}
				},
				required: {},
				comps: {}
			}

		}

	},

	computed: {

		contentTabs: function() {

			return [
				{ name: 'usage', text: 'Usage' },
				{ name: 'items', text: 'Items' },
				{ name: 'other', text: 'Other' }
			]

		},

		amountValueMin: function() {

			return this.model.type === this.$constants.registration.discount.type.comp ? 0 : 1

		},

		amountValueMax: function() {

			return this.model.type === this.$constants.registration.discount.type.percent ? 100 : 10000

		}

	}

}

</script>

<style scoped>

</style>